import Avatar from "@/components/Avatar";
import { Agent, ConversationBase, HubMessages } from "@/types";
import React from "react";

interface Props {
  agent: Agent;
  showAvailability: boolean;
  notSelectable: boolean;
  selected?: boolean;
  setAgentInfo(agent: Agent): void;
}

export default function AgentListItem(props: Props) {
  function activateAgentInfo() {
    props.setAgentInfo(props.agent);
  }

  const availabilityColor =
    props.agent.status === "Available" ? "bg-success-500" : "bg-danger-500";

  return (
    <>
      {!props.notSelectable && (
        <button
          className={
            props.selected
              ? "hover:bg-cornflower-blue-100 rounded-md bg-cornflower-blue-100"
              : "hover:bg-cornflower-blue-50 rounded-md"
          }
          onClick={() => activateAgentInfo()}
        >
          <div className="flex flex-row w-[352px] pb-2 py-2">
            <div className="flex pl-2 pr-3">
              <Avatar
                name={props.agent.displayName}
                url={props.agent.picture}
                size={24}
                fontSize={12}
              />
            </div>
            <div className="flex w-[280px]">{props.agent.displayName}</div>
            <div className="flex items-center pl-3">
              {props.showAvailability && (
                <div
                  className={
                    availabilityColor + " flex w-2 h-2 pr-2 rounded-full"
                  }
                ></div>
              )}
            </div>
          </div>
        </button>
      )}

      {props.notSelectable && (
        <div className="rounded-md">
          <div className="flex flex-row w-[352px] pb-2 py-2">
            <div className="flex pr-4">
              <Avatar
                name={props.agent.displayName}
                url={props.agent.picture}
                size={40}
              />
            </div>
            <div className="flex w-[280px] text-sm leading-[21px] font-semibold items-center">
              {props.agent.displayName}
            </div>
            <div className="flex items-center pl-3">
              {props.showAvailability && (
                <div
                  className={
                    availabilityColor + " flex w-2 h-2 pr-2 rounded-full"
                  }
                ></div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
