import React from "react";
import { ConversationBase } from "@/types";
import ResolveConversationModal from "./ResolveConversationModal";
import useTranslations from "@/hooks/useTranslations";
import ChatAssignmentModal from "./ChatAssignmentModal";
import IconPersonAdd from "@/icons/IconPersonAdd";

interface Props {
  conversation: ConversationBase;
}

export default function ChatAssignmentButton(props: Props) {
  const [openAssignmentModal, setOpenAssignmentModal] = React.useState(false);
  const translation = useTranslations();

  return (
    <>
      <div>
        <button
          className="h-16 w-14"
          onClick={() => setOpenAssignmentModal(true)}
        >
          <div className="flex flex-col items-center">
            <IconPersonAdd className="h-6" />
            <div className="text-xs">Assign</div>
          </div>
        </button>
      </div>
      {openAssignmentModal && (
        <ChatAssignmentModal
          conversation={props.conversation}
          onClose={() => setOpenAssignmentModal(false)}
        />
      )}
    </>
  );
}
