import React, { useMemo } from "react";
import { ConversationBase } from "@/types";
import useApi from "@/hooks/useApi";
import useProfile from "@/hooks/useProfile";
import ResolveConversationButton from "./ResolveConversationButton";
import IconPersonAdd from "@/icons/IconPersonAdd";
import IconPersonRemove from "@/icons/IconPersonRemove";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useTranslations from "@/hooks/useTranslations";
import ChatAssignmentButton from "./ChatAssignmentButton";

interface Props {
  conversation: ConversationBase;
}

export default function ConversationHeader(props: Props) {
  const api = useApi();
  const { profile } = useProfile();
  const toastAlert = useToastAlert();
  const translation = useTranslations();

  async function assignToMe() {
    // setAssigning(true);

    const response = await api.messaging.post("AssignAgent", {
      userId: profile.id,
      conversationBaseId: props.conversation.id
    });

    // setAssigning(false);

    if (!response.ok) {
      if (response.status === 400) {
        alert(await response.text());
      } else {
        toastAlert.displayToast(Severity.Error, "an unexpected error occurred");
      }
    } else {
      // onSuccess();
    }
  }

  async function unassign() {
    const response = await api.messaging.post("AssignAgent", {
      userId: null,
      conversationBaseId: props.conversation.id
    });

    if (!response.ok) {
      if (response.status === 400) {
        alert(await response.text());
      } else {
        toastAlert.displayToast(Severity.Error, "an unexpected error occurred");
      }
    } else {
      // onSuccess();
      const updatedAssignment = await api.messaging.get(
        `ConversationBase/${props.conversation.id}`
      );
      const info = await updatedAssignment.json();
    }
  }

  const assignedUser = useMemo(() => {
    if (props.conversation.assignedUser) {
      let n =
        props.conversation.assignedUser.name ??
        props.conversation.assignedUser.userName;

      if (props.conversation.assignedUserId == profile.id) {
        n = translation.key("me");
      }
      return `${translation.key("assigned_to")} ${n}`;
    } else {
      return translation.key("unassigned");
    }
  }, [
    profile.id,
    props.conversation.assignedUser,
    props.conversation.assignedUserId,
    translation
  ]);

  const showClaimButton =
    !props.conversation.assignedUser && props.conversation.status != "Resolved";

  const showUnassignButton =
    (props.conversation.assignedUser &&
      props.conversation.status != "Resolved" &&
      props.conversation.assignedUserId == profile.id) ||
    (profile.roles?.includes("Administrator") &&
      props.conversation.assignedUser);

  const showCloseButton =
    props.conversation.status == "Open" &&
    (!props.conversation.assignedUserId ||
      props.conversation.assignedUserId == profile.id ||
      profile.roles?.includes("Administrator"));

  return (
    <>
      <div className="conversation-header flex h-16 items-center">
        <div className="ml-4">
          <div className="font-bold">{props.conversation.contact.name}</div>
          <div className="text-sm text-slate-gray-400">{assignedUser}</div>
        </div>
        <div className="ml-auto flex items-center">
          <ChatAssignmentButton conversation={props.conversation} />
          {import.meta.env.DEV && (
            <>
              {showClaimButton && (
                <button className="h-16 w-20" onClick={() => assignToMe()}>
                  <div className="flex flex-col items-center">
                    <IconPersonAdd className="h-6" />
                    <div className="text-xs">
                      {translation.key("assign_to_me")}
                    </div>
                  </div>
                </button>
              )}
            </>
          )}
          {showUnassignButton && (
            <button className="h-16 w-14" onClick={() => unassign()}>
              <div className="flex flex-col items-center">
                <IconPersonRemove className="h-6" />
                <div className="text-xs">{translation.key("unassign")}</div>
              </div>
            </button>
          )}
          {showCloseButton && (
            <ResolveConversationButton conversation={props.conversation} />
          )}

          {/* <button className="h-16 w-10">
            <div className="flex flex-col items-center">
              <img src={priorityIcon} />
            </div>
          </button> */}
          {/* <ConversationContextMenu conversation={props.conversation} /> */}
        </div>
      </div>
      {/* <div className="conversation-header relative h-14 border-b">
        <div className="flex items-center h-full">
          <div className="text-lg font-semibold">
            {props.conversation.contact.name}
          </div>
          <div className="flex items-center ml-auto">
            <button onClick={resolveConversation} className="text-green">
              <i className="fa fa-check"></i> Resolve
            </button>
            <button
              className="w-14 h-14"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <i className="fas fa-ellipsis-h" />
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
}
