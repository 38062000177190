import React from "react";
import { ConversationBase } from "@/types";
import useApi from "./useApi";
import useSubscribe from "./useSubscribe";

export default function usePastConversations(contactId: number) {
  const api = useApi();

  const [pastConversations, setPastConversations] = React.useState<
    ConversationBase[]
  >([]);
  const subscribe = useSubscribe();

  const fetchConversations = React.useCallback(async () => {
    setPastConversations([]);
    if (isNaN(contactId)) {
      console.error("contact id is not a number");
      return;
    }

    const response = await api.messaging.get(
      `PastConversations/List/${contactId}`
    );

    const data = await response.json();

    setPastConversations(data.items);
  }, [api.messaging, contactId]);

  React.useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  React.useEffect(
    () =>
      subscribe("conversation-resolved", (message) => {
        fetchConversations();
      }),
    [subscribe]
  );

  function updatePastConversation(
    pastConvo: Partial<ConversationBase>,
    convoId: number
  ) {
    const foundConvoIndex = pastConversations.findIndex(
      (x) => x.id === convoId
    );
    const newState = [...pastConversations];
    newState[foundConvoIndex] = { ...newState[foundConvoIndex], ...pastConvo };
    setPastConversations(newState);
  }

  return { pastConversations, updatePastConversation };
}
