import React from "react";
import { ConversationBase } from "@/types";
import ResolveConversationModal from "./ResolveConversationModal";
import IconCheckMark from "@/icons/IconCheckMark";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  conversation: ConversationBase;
}

export default function ResolveConversationButton(props: Props) {
  const [showModal, setShowModal] = React.useState(false);
  const translation = useTranslations();

  return (
    <>
      <button className="h-16 w-14" onClick={() => setShowModal(true)}>
        <div className="flex flex-col items-center">
          <IconCheckMark className="h-6" />
          <div className="text-xs">{translation.key("close")}</div>
        </div>
      </button>
      {showModal && (
        <ResolveConversationModal
          conversation={props.conversation}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}
