import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import useApi from "@/hooks/useApi";
import useNetSuiteConfigs from "@/hooks/useNetSuiteConfigs";
import { ConversationBase } from "@/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import CrmSearchResult from "../CrmSearchResult";
import Panel from "../Panel";
import NetSuiteModal from "./NetSuiteModal";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  conversation: ConversationBase;
}

interface NetSuiteSearchResult {
  recordType: string;
  id: string;
  fields: Record<string, any>;
  showInSearchFields: string[];
  showOnSelectFields: string[];
}

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

export default function NetSuitePanel(props: Props) {
  const [contact, setContact] = useState({
    ...props.conversation.contact
  });
  if (contact.id !== props.conversation.contact.id) {
    setContact({
      ...props.conversation.contact
    });
  }
  const { items, loading } = useNetSuiteConfigs(props.conversation.groupId!);
  const [searchText, setSearchText] = useState("");
  const [searchFieldType, setSearchFieldType] = useState<Option[]>([]);
  const [searchTypes, setSearchTypes] = useState<Option[]>([]);
  const [searchResults, setSearchResults] = useState<NetSuiteSearchResult[]>(
    []
  );
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [modalRecordType, setModalRecordType] = useState("");
  const [selectedSearchType, setSelectedSearchType] = useState<Option[]>([]);
  const [selectedSearchFieldType, setSelectedSearchFieldType] = useState<
    Option[]
  >([]);
  const [selectedConfig, setSelectedConfig] = useState<number>(items[0]?.id);
  const [readyToSearch, setReadyToSearch] = useState<boolean>(true);

  const api = useApi();
  const toastAlert = useToastAlert();
  const tranlsation = useTranslations();

  useEffect(() => {
    if (!loading) {
      setSelectedConfig(items[0]?.id);
    }
  }, [items, loading]);

  useEffect(() => {
    if (searchResults.length === 0) {
      setReadyToSearch(true);
      return;
    }
    setReadyToSearch(false);
  }, [searchResults.length]);

  useEffect(() => {
    if (searchResults.length === 0) {
      setReadyToSearch(true);
      return;
    }
    setReadyToSearch(false);
  }, [searchResults.length]);

  async function search(
    searchText: string,
    searchRecordType: Option[],
    selectedSearchFieldType: Option[]
  ) {
    if (!searchText || !searchRecordType || !selectedSearchFieldType) {
      console.log("One or more fields empty in search");
      toastAlert.displayToast(Severity.Error, "One or more fields empty.");
      return;
    }
    setSearchResults([]);
    setSearchLoading(true);
    const standardizedSearchRecordType = searchRecordType.map(
      (map) => map.value
    );
    const standardizedSearchFieldType = selectedSearchFieldType.map(
      (map) => map.value
    );

    const searchResp = await api.messaging.post("NetSuite/Search", {
      selectedConfig: selectedConfig,
      searchRecordType: standardizedSearchRecordType,
      searchText: searchText,
      searchFieldType: standardizedSearchFieldType
    });

    const showInSearchResp = await api.messaging.post(
      "NetSuite/Search/ShowInSearch",
      {
        selectedConfig: selectedConfig,
        searchRecordType: standardizedSearchRecordType
      }
    );

    const showOnSelectResp = await api.messaging.post(
      "NetSuite/Search/ShowOnSelect",
      {
        selectedConfig: selectedConfig,
        searchRecordType: standardizedSearchRecordType
      }
    );

    const showInSearchJson = await showInSearchResp.json();
    const showOnSelectJson = await showOnSelectResp.json();

    if (searchResp.ok) {
      const json = await searchResp.json();
      let clone = [...searchResults];
      searchRecordType.forEach((element) => {
        const jsonFields = json[element.value];
        const newResults = Object.values(jsonFields).map((key, i) => ({
          recordType: element.value,
          id: element.value + i,
          fields: key,
          showInSearchFields: showInSearchJson[element.value],
          showOnSelectFields: showOnSelectJson[element.value]
        })) as NetSuiteSearchResult[];
        clone = [...clone, ...newResults];
      });
      setSearchResults(clone);
    } else {
      toastAlert.displayToast(Severity.Error, "An unexpected error occurred.");
    }
    setSearchLoading(false);
  }

  const fetchSearchFields = useCallback(async () => {
    if (selectedSearchType.length === 0) {
      setSearchFieldType([]);
      return;
    }
    const recordTypes = selectedSearchType.map((type) => type.value);
    const resp = await api.messaging.post(
      `NetSuite/Search/Fields/${selectedConfig}`,
      {
        selectedConfig: selectedConfig,
        searchRecordType: recordTypes
      }
    );

    const json = await resp.json();
    const options: Option[] = [];
    json.forEach((element: any) => {
      const value = {
        value: element,
        label: element
      };
      options.push(value);
    });
    setSearchFieldType(options);
  }, [api.messaging, selectedConfig, selectedSearchType]);

  useEffect(() => {
    if (selectedSearchType.length === 0) return;
    fetchSearchFields();
  }, [fetchSearchFields, selectedSearchType]);

  // This is the REST method of saving records, this is currently not supported for all record types in NetSuite so we are leaving commented for now
  // async function add() {
  //   const resp = await api.messaging.post("NetSuite/Integrations/SaveRecord", {
  //     id: props.conversation.contact.id,
  //     email: props.conversation.contact.email,
  //     phone: props.conversation.contact.phone,
  //     name: props.conversation.contact.name
  //   });

  //   if (resp.ok) {
  //     const data = await resp.json();
  //   } else {
  //     toastAlert.displayToast(Severity.Error, "An unexpected error occurred.");
  //   }
  // }

  const onClickSearch = () => {
    search(searchText, selectedSearchType, selectedSearchFieldType);
  };

  useEffect(() => {
    const options = [
      "Customer",
      "Account",
      "SupportCase",
      "Lead",
      "Prospect",
      "Contact",
      "Order"
    ];
    const newOptions = Object.values(options).map((key) => ({
      value: key,
      label: tranlsation.key(key.toLowerCase())
    })) as Option[];
    setSearchTypes(newOptions);
  }, []);

  if (!items) {
    return null;
  }

  return (
    <Panel title="NetSuite">
      <div className="p-4">
        <div className="mb-4">
          <div className="font-bold dark:text-white">
            {tranlsation.key("netsuite_login")}
          </div>
          <select
            className="input w-full"
            value={selectedConfig}
            onChange={(e) => setSelectedConfig(Number(e.target.value))}
          >
            {items.map((item) => (
              <option key={item.id} value={item.id}>
                {item.displayName}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <div className="font-bold dark:text-white">
            {tranlsation.key("search_record_type")}
          </div>
          <MultiSelect
            className="w-full mb-3"
            options={searchTypes}
            value={selectedSearchType}
            onChange={setSelectedSearchType}
            labelledBy="Select"
          ></MultiSelect>
        </div>
        <div className="mb-4">
          <div className="font-bold dark:text-white">
            {tranlsation.key("search_field_type")}
          </div>
          <MultiSelect
            className="w-full mb-3"
            options={searchFieldType}
            value={selectedSearchFieldType}
            onChange={setSelectedSearchFieldType}
            labelledBy="Select"
          ></MultiSelect>
        </div>
        <div className="mb-4">
          <div className="font-bold dark:text-white">
            {tranlsation.key("search_value")}
          </div>
          <input
            type="search"
            className="input w-full"
            placeholder={tranlsation.key("search") + "..."}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="btn-green mb-4"
          onClick={onClickSearch}
        >
          {tranlsation.key("search")}
        </button>
        {selectedSearchType.length > 0 &&
          selectedSearchType.map((type, i) => (
            <div className="mb-4" key={i}>
              {type.value !== "Order" && (
                <button
                  type="button"
                  className="h-7 flex items-center border rounded px-2 mr-1 mb-1 text-sm"
                  onClick={() => {
                    setShowModal(true);
                    setModalRecordType(type.value);
                  }}
                >
                  <i className="fa fa-plus"></i>
                  <span className="ml-1">Add {type.value} to NetSuite</span>
                </button>
              )}
            </div>
          ))}
        {searchLoading && <LoadingSpinner />}
        {searchResults.length > 0 && (
          <div className="mb-4 font-bold dark:text-white">Search Results</div>
        )}
        {searchResults.map((result) => (
          <CrmSearchResult
            searchResult={result}
            key={result.id}
            conversation={props.conversation}
            showModal={() => {
              setShowModal(!showModal);
              setModalRecordType(result.recordType);
            }}
          />
        ))}
        {showModal && (
          <NetSuiteModal
            contact={contact}
            onClose={() => setShowModal(false)}
            recordType={modalRecordType}
            selectedConfig={selectedConfig}
          />
        )}
      </div>
    </Panel>
  );
}
