import { useEffect, useState } from "react";
import useApi from "./useApi";
import { Call } from "@/types";
import useToastAlert from "./useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useSubscribe from "./useSubscribe";

export default function useCall(callId: number) {
  const api = useApi();
  const toastAlert = useToastAlert();
  const subscribe = useSubscribe();
  const [call, setCall] = useState<Call | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const resp = await api.messaging.get(`Call/${callId}`);
      if (resp.ok) {
        setCall(await resp.json());
      } else {
        toastAlert.displayToast(
          Severity.Error,
          "An unexpected error occurred while loading the call."
        );
      }
      setLoading(false);
    }

    fetch();
  }, [api.messaging, callId, toastAlert]);

  useEffect(
    () =>
      subscribe("call-updated", (message) => {
        console.log("Call updated", message.call);
        setCall(message.call);
      }),
    [subscribe]
  );

  return { call, loading };
}
