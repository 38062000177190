import TextInput from "@/components/DynamicForm/controls/TextInput";
import {
  Contact,
  ConversationBase,
  ConversationSource,
  SentimentType
} from "@/types";
import format from "date-fns/format";
import React, { useState } from "react";
import ConversationStatus from "@/components/ConversationStatus";
import FormattedTime from "./FormattedTime";
import useConversationSourceLabel from "@/hooks/useConversationSourceLabel";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  hideAngle?: boolean;
  expanded: boolean;
  onClick?(): void;
  setTopic(topic: string): void;
  conversation: ConversationBase;
  messageCount: number;
}

export default function ConversationPanelHeader(props: Props) {
  let bgClass =
    "bg-blue-50 dark:bg-stone-400 dark:text-white rounded-t hover:bg-blue-100";
  let angleClassName = "ml-1 transition fa-solid fa-angle-down";

  if (props.expanded) {
    angleClassName += " rotate-180";
    bgClass = "bg-blue-50 dark:bg-stone-400 dark:text-white hover:bg-blue-100";
  }

  const getSourceLabel = useConversationSourceLabel();
  const conversationSourceLabel = getSourceLabel(props.conversation.source);
  const translation = useTranslations();

  const startDate = format(
    new Date(props.conversation.startTimestamp),
    "M/d/yyyy"
  );

  function renderSentiment() {
    if (!props.conversation.sentiment) {
      return null;
    }

    let icon;

    switch (props.conversation.sentiment) {
      case "positive":
        icon = (
          <i className="w-6 fa-solid fa-face-smile text-success-green-500"></i>
        );
        break;
      case "negative":
        icon = (
          <i className="w-6 fa-solid fa-face-frown text-crimson-red-500"></i>
        );
        break;
      default:
        icon = <i className="w-6 fa-solid fa-face-meh text-yellow-500"></i>;
        break;
    }

    return (
      <div className="flex items-center">
        {icon}
        <span>{props.conversation.sentiment}</span>
      </div>
    );
  }

  return (
    <div className="p-4 flex flex-col items-left text-left w-full rounded-lg text-slate-gray-600 bg-white gap-2 ">
      <div className="flex flex-row text-sm" style={{ width: "100%" }}>
        <div className="flex flex-row flex-wrap items-center text-left w-full gap-y-2">
          <div className="flex flex-row text-gray-400 text-xs leading-none">
            via: {conversationSourceLabel}
          </div>
          <div className="text-gray-400 text-xs leading-none ml-2">
            {startDate}
          </div>
          <div className="text-gray-400 text-xs leading-none ml-2 mr-4">
            <FormattedTime isoDate={props.conversation.startTimestamp} />
          </div>
          <div className="flex">
            {props.conversation.assignedUser?.name != null &&
              props.conversation.assignedUser.name != "" && (
                <div className="h-6 mr-2 px-1.5 py-1 bg-slate-gray-100 rounded border border-slate-gray-100 justify-center items-center gap-2 inline-flex text-center text-zinc-700 text-xs font-medium font-['Rubik'] leading-[15px] whitespace-nowrap">
                  {props.conversation.assignedUser.name}
                </div>
              )}
            <div>
              <ConversationStatus status={props.conversation.status} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row whitespace-nowrap overflow-hidden overflow-ellipsis w-full">
        <input
          type="text"
          className="items-center block w-full h-6 pl-0 text-zinc-700 text-sm font-semibold font-['Rubik'] leading-none input bg-transparent border-transparent focus:bg-white focus:dark:bg-slate-gray-500 hover:bg-white hover:dark:bg-slate-gray-500 hover:border-cornflower-blue-50 "
          required
          value={props.conversation.topic}
          onChange={(e) => props.setTopic(e.currentTarget.value)}
        />
      </div>
      <div
        className="flex flex-row items-center justify-center text-center"
        style={{ width: "10%" }}
      >
        <div className="flex flex-row items-center justify-center text-center w-full"></div>
        {/* <div className="flex items-center w-28">
            <i className="w-6 fa-solid fa-user-group"></i>
            <span>[group]</span>
          </div> */}

        {renderSentiment()}
      </div>
      <div className="flex flex-row">
        <button
          className={`flex flex-row items-center justify-center"`}
          onClick={props.onClick}
        >
          {props.messageCount == 1 && (
            <div className="text-blue-500 text-xs font-semibold font-['Rubik'] leading-none">
              {props.messageCount} reply
              <i className={angleClassName}></i>
            </div>
          )}
          {props.messageCount > 1 && (
            <div className="text-blue-500 text-xs font-semibold font-['Rubik'] leading-none">
              {props.messageCount} {translation.key("replies")}
              <i className={angleClassName}></i>
            </div>
          )}
        </button>
      </div>
    </div>
  );
}
