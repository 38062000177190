import Avatar from "@/components/Avatar";
import useApi from "@/hooks/useApi";
import useCall from "@/hooks/useCall";
import useProfile from "@/hooks/useProfile";
import useToastAlert from "@/hooks/useToastAlert";
import { ConversationBase } from "@/types";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import React, { useMemo, useState } from "react";

interface Props {
  call: ConversationBase;
}

export default function CallView(props: Props) {
  const { profile } = useProfile();
  const api = useApi();
  const toastAlert = useToastAlert();
  const { call, loading } = useCall(props.call.id);
  const [calling, setCalling] = useState(false);

  async function handleAcceptCall() {
    setCalling(true);
    const response = await api.messaging.post(
      `Call/AcceptCall/${props.call.id}`
    );
    if (response.ok) {
      console.log("Call accepted");
    } else {
      const error = await response.text();
      toastAlert.displayToast(
        Severity.Error,
        `An unexpected error occurred: ${error}`
      );
    }
    setCalling(false);
  }

  const callStateMap: { [key: string]: string } = {
    Created: `${props.call.contact.name} has initiated a call.`,
    ConnectingContact: "Call is connecting to the contact.",
    ContactConnected: "Contact has answered the call.",
    ConnectingAgent: "Call is connecting to the agent.",
    AgentConnected: "Call is in progress.",
    ContactMissed: "Contact did not answer the call.",
    AgentMissed: "Agent did not answer the call.",
    Completed: "Call is complete."
  };

  const displayName = useMemo(() => {
    if (props.call?.contact.name) {
      return props.call.contact.name;
    } else {
      return props.call?.contact.phone;
    }
  }, [props.call.contact.name, props.call.contact.phone]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center my-6">
        <Avatar
          url={props.call.contact.picture}
          name={props.call.contact.displayName}
          size={100}
          fontSize={"2rem"}
        />
        {/* in this div display contact name, if there is no name then display contact phone */}
        <div className="text-xl font-semibold mt-2">{displayName}</div>
        <div className="text-lg mt-2">
          {callStateMap[call?.callState || ""] || "Unknown call state."}
        </div>
      </div>
      {profile.id === props.call.assignedUserId &&
        call?.callState === "Created" && (
          <div className="flex justify-center gap-4">
            <PrimaryBlueButton
              label="Accept Call"
              onClick={() => handleAcceptCall()}
              disabled={calling}
            />
          </div>
        )}
    </>
  );
}
