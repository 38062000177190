import { ConversationBase, ConversationBaseListItem } from "@/types";
import React from "react";
import Avatar from "./Avatar";
import IconClose from "@velaro/velaro-shared/src/icons/IconClose";
import IconReassignmentArrow from "@/icons/IconReassignArrow";
import { id } from "date-fns/locale";
import { NavLink, redirect, useNavigate } from "react-router-dom";

interface Props {
  conversation: ConversationBaseListItem;
  previousAssignedUser?: string;
  message: string;
  id: string;
  onClose(id: string): void;
  goToConversation(conversationBaseId: number, alertId: string): void;
}

export default function ReassignmentNotification(props: Props) {
  return (
    <div className=" w-96 h-[213px] rounded-lg bg-cornflower-blue-100 flex flex-col mb-4 shadow-lg z-50 pointer-events-auto">
      <div className="flex flex-row items-center justify-center w-full h-12  pl-4 pt-4 text-cornflower-blue-800">
        <div className="pr-3">
          <Avatar
            url={props.conversation.contactPic}
            name={props.conversation.contactName}
            size={36}
          />
        </div>
        <div>
          <div className="text-sm font-medium">
            {props.conversation.contactName}
          </div>
          <div className="text-xs font-normal text-slate-gray-400 dark:text-white">
            Via {props.conversation.source}
          </div>
        </div>
        <button
          className="ml-auto h-10 w-10 text-slate-gray-500"
          onClick={() => props.onClose(props.id)}
        >
          <IconClose className="w-6 h-6 text-cornflower-blue-800" />
        </button>
      </div>
      <div className="py-4 flex items-center justify-center">
        <div className="mx-4 grow shrink basis-0 h-px bg-slate-gray-200"></div>
      </div>
      <div className="flex flex-row mx-4 mb-2">
        <div className="pr-2 align-top">
          <IconReassignmentArrow />
        </div>
        <div className="flex flex-col">
          <div className="flex text-cornflower-blue-800 font-normal text-sm">
            {props.previousAssignedUser !== null && (
              <>
                <span className="">Transferred from Agent</span>
                <span className="pl-1">{props.previousAssignedUser}</span>
              </>
            )}
            {/* {props.previousAssignedUser == null && (
              <>
                <span className="">Transferred to Team</span>
                <span className="pl-1">{props.teamName}</span>
              </>
            )} */}
          </div>
          <div className="flex flex-row items-center justify-center">
            <div className="w-full h-10 text-sm overflow-hidden overflow-ellipsis">
              {props.message}
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center">
        <button
          className="flex h-10 w-[350px] mx-4 mb-4 mt-3 rounded-md bg-cornflower-blue-500 text-white align-middle items-center justify-center text-center"
          onClick={() =>
            props.goToConversation(props.conversation.id, props.id)
          }
        >
          Join Conversation
        </button>
      </div>
    </div>
  );
}
